<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" divider=">" class="px-0"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-diglog-confirm
      :show="isLeave"
      :subTitle="subTitle"
      :close="close"
      :buttonText="buttonConfirmText"
      :confirm="confirm"
      :buttonCancel="buttonCancel"
    />
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-error :show="isError" :close="closeAlert"></v-alert-error>
    <v-alert-warning :show="isWarning" :close="closeAlert"></v-alert-warning>

    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <!-- Headline text -->
    <v-row>
      <v-col>
        <p t-data="page-headline" class="headline">{{ textMode }} User</p>
      </v-col>
    </v-row>

    <!-- Form -->
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col>
          <v-row t-data="info-label">
            <v-col>Status</v-col>
          </v-row>

          <v-row>
            <v-col t-data="info-input">
              <v-radio-group v-model="userObject.is_active">
                <v-radio :key="true" :value="true" label="Active"></v-radio>
                <v-radio
                  :key="false"
                  :value="false"
                  label="Inactive"
                  @change="valueStatusChange(0)"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn t-data="save-btn" class="ma-2" large color="primary" @click="save">Save</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-text-field
            label="* E-mail"
            required
            @keypress="validateEmailChar($event)"
            :rules="EmailRules"
            v-model="emailValue"
            :error-messages="chkEmailErrorMsg"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-text-field
            label="* Firstname"
            required
            @keypress="isAplabet($event)"
            :rules="FirstNameRules"
            v-model="userObject.first_name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-text-field
            label="* Lastname"
            required
            @keypress="isAplabet($event)"
            :rules="LastNameRules"
            v-model="userObject.last_name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-text-field
            label="Position"
            required
            v-model="userObject.position"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-select
            v-model="userObject.org"
            :items="orgObject.data"
            :rules="OrgRules"
            item-text="name"
            item-value="codename"
            label="* Organization"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="2" t-data="role-headline">*User roles</v-col>

      <span
        class="red--text"
        t-data="required-warning"
      >{{this.selectRoleOrgData.length === 0 ? this.roleFirstSelect ? 'Please set user role at least one role.' :'' : ''}}</span>
    </v-row>
    <v-expansion-panels :multiple="true">
      <v-expansion-panel v-for="(item,i) in this.orgRoleObject" :key="i">
        <v-expansion-panel-header>{{item.name}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div t-data="roles-input-label" v-for="role in item.org_roles" v-bind:key="role.id">
            <input
              type="checkbox"
              :id="role.id"
              :value="role.id"
              :checked="roleChecked(role.id)"
              @change="roleChange()"
              style="width:30% height:30%"
              v-model="userRoles"
            />
            <label style="margin-left:10px">{{role.codename}}</label>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import AlertWarning from '../../components/AlertWarning'

import AlertSuccess from '../../components/AlertSaveUserSuccess'
import Confirm from '../../components/AlertConfirm'

export default {
  components: {
    'v-popup-loading': PopupLoading,
    'v-alert-error': AlertError,
    'v-alert-success': AlertSuccess,
    'v-diglog-confirm': Confirm,
    'v-alert-warning': AlertWarning
  },
  data() {
    return {
      action: {
        org: '',
        dropdownActions: []
      },
      roleFirstSelect: false,
      emailValue: '',
      chkEmailErrorMsg: [],
      isError: false,
      isActive: true,
      isWarning: false,
      isSuccess: false,
      buttonConfirmText: 'Leave',
      buttonCancel: 'Stay',
      subTitle:
        'You have changed the information.\nAre you sure to leave this page?',
      isSave: false,
      to: null,
      isLeave: false,
      showDialog: false,
      EmailRules: [
        v => !!v || 'E-mail is required',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid'
      ],
      FirstNameRules: [
        v => !!v || 'Firstname is required',
        v => /^[ก-๛a-zA-Z]+$/.test(v) || 'Invalid characters'
      ],
      OrgRules: [v => !!v || 'Organization is required'],

      LastNameRules: [
        v => !!v || 'Lastname is required',
        v => /^[ก-๛a-zA-Z]+$/.test(v) || 'Invalid characters'
      ],
      orgRules: [v => !!v || 'Title is required'],
      isEditMode: false
    }
  },
  destroyed() {
    this.isSuccess = true
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setOrgInfo()
    this.setOrgRoleInfo()

    if (this.isEditMode) {
      await this.setUserId(this.$route.params.id)
      this.emailValue = this.userObject.email
      return
    } else {
      this.resetState()
    }
  },
  computed: {
    userRoles: {
      set(val) {
        this.setSelectedRoleOrg(val)
      },
      get() {
        return this.selectRoleOrgData
      }
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Add'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Role & Permission', links: true, to: '/user-roles'},
        {text: 'User', links: false},

        {text: this.textMode, links: false}
      ]
    },
    ...mapState('userRole', [
      'isDelete',
      'isResetPassword',
      'isPreview',
      'usersList',
      'isLoading',
      'totalUsers',
      'updateIdList',
      'userObject',
      'orgObject',
      'orgRoleObject',
      'selectRoleOrgData'
    ])
  },
  methods: {
    roleChange() {
      this.roleFirstSelect = true
    },
    roleChecked(id) {
      return this.selectRoleOrgData.includes(id.toString())
    },
    updateRoles(event) {
      this.setSelectedRoleOrg(event.target.value)
    },
    isAplabet: function (evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if ((charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90) ||
        (charCode >=161 && charCode <= 238) || (charCode >= 3585 && charCode <= 3662)) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    validateEmailChar: function (evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if ((charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90) || (charCode >= 48 && charCode <= 57)
        || [33, 35, 36, 37, 38, 39, 42, 43, 45, 46, 47, 61, 63, 64, 94, 95, 96, 123, 124, 125, 126].includes(charCode)
      ) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    isNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    close() {
      this.isLeave = false
      this.to = null
    },
    confirm() {
      this.isLeave = false
      this.$router.push(this.to)
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
      this.isWarning = false
    },
    valueStatusChange(value) {
      if (value === 0) {
        this.isWarning = true
      }
    },
    async save() {
      if (this.validate()) {
        this.isSave = true
        if (!this.isEditMode) {
          this.userObject.email = this.emailValue
          this.userObject.username = this.userObject.email
          this.userObject.roles = this.selectRoleOrgData

          let status = await this.create(this.userObject)
          if (status) {
            this.$refs.form.resetValidation()
            this.emailValue = ''
            this.userObject.email = ''
            this.roleFirstSelect = false

            return (this.isSuccess = true)
          }
          return (this.isError = true)
        }
        if (this.isEditMode) {
          this.userObject.email = this.emailValue

          this.userObject.username = this.userObject.email
          this.userObject.roles = this.selectRoleOrgData

          const payload = {
            pkg: this.userObject,
            id: this.$route.params.id
          }
          let status = await this.updatePkg(payload)
          if (status) {
            return (this.isSuccess = true)
          }
          return (this.isError = true)
        }
      } else {
        this.isError = true
      }
    },
    validate() {
      this.roleFirstSelect = true
      if (
        this.$refs.form.validate() &&
        this.chkEmailErrorMsg.length === 0 &&
        this.selectRoleOrgData.length !== 0
      ) {
        return true
      }
    },
    ...mapActions('userRole', [
      'fetch',
      'update',
      'setUserId',
      'resetState',
      'setOrgInfo',
      'setOrgRoleInfo',
      'create',
      'setSelectedRoleOrg',
      'updatePkg',
      'chkExistingEmail'
    ])
  },
  watch: {
    async emailValue(email) {
      if (email === '') {
        this.chkEmailErrorMsg = []
      }
      else if (this.isEditMode) {
        this.chkEmailErrorMsg = await this.chkExistingEmail({email, id: this.userObject.id}) ? [] : ['E-mail already exists.']
      }
      else {
        this.chkEmailErrorMsg = await this.chkExistingEmail({email}) ? [] : ['E-mail already exists.']
      }
    }
  }
}
</script>

<style scoped>
.v-form .col,
.container .col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

