<template>
  <v-dialog v-model="show" persistent max-width="400">
    <v-card>
      <v-card-title class="headline" t-data="headline">
        <span class="orange--text">Attention</span>
        <v-icon color="orange">error</v-icon>
      </v-card-title>
      <v-card-text t-data="card-text">
        If you set this user status to
        <span class="red--text">Inactive</span>
      </v-card-text>
      <v-card-text t-data="card-text">User will not be allowed access to portal</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black darken-1" t-data="action-btn" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertWarning',
  props: {
    show: {type: Boolean},
    close: {type: Function}
  },
  methods: {
    closePopup() {
      this.close()
    }
  }
}
</script>


<style scoped>
</style>

