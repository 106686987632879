<template>
  <v-dialog v-model="show" persistent max-width="400">
    <v-card>
      <v-card-title class="headline" t-data="headline">
        <span class="blue--text">Success</span>
        <v-icon color="blue">check</v-icon>
      </v-card-title>
      <v-card-text t-data="card-text">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black darken-1" t-data="action-btn" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertSaveUserSuccess',
  props: {
    show: {type: Boolean},
    close: {type: Function},
    text: {type: String},
  },
  methods: {
    closePopup() {
      this.close()
    }
  },
  computed:{
    message(){
      return this.text ? this.text : 'User information has been saved.'
    }
  }
}
</script>


<style scoped>
</style>

